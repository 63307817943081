import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

import Car from "./car";
import Label from "./label";
import Layout from "./layout";
import Loader from "./loader";
import Navbar from "./navbar";
import AppContext from "../app-context";
import {HOME} from "../constants/routes";
import useTranslations from "../hooks/use-translations";
import {Civility} from "../constants";
import {DamageSeverity} from "../models/enums/damage-severity";
import CurrentTimeDisplay from "./current-time-display.tsx";

const ONE_HOUR = 60 * 60000;

const Summary = () => {
  const navigate = useNavigate();
  const { isTranslationsLoading, report } = useContext(AppContext);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentPlate, setCurrentPlate] = useState<string>("");
  useTranslations(report?.customerInfo?.language);

  const { companyName, civility, firstName, lastName } = report.customerInfo ?? {};
  let displayName = companyName;

  if (civility === Civility.MR || civility === Civility.MS) {
    if (firstName && firstName?.length > 0 && lastName && lastName?.length > 0) {
      displayName = `${firstName} ${lastName}`;
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(`/${HOME}`);
    }, ONE_HOUR); // In case of dysfunctional leave event

    if (report?.registration && report?.registration !== currentPlate) {
        setCurrentPlate(report.registration);
        setIsTransitioning(true);
        setTimeout(() => setIsTransitioning(false), 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [navigate, report]);

    if (isTranslationsLoading) {
        return <Loader/>;
    }


    if (isTransitioning || currentPlate !== report.registration) {
        return (
            <Layout className="flex flex-col items-center justify-center">
                <div className="flex-col justify-between items-center inline-flex h-[70%]">
                    <div className="grow shrink basis-0 flex-col justify-center items-center gap-[50px] flex">
                        <p className="text-2vw text-white/60">
                            <FormattedMessage id="transitioning" defaultMessage="Plate detection in progress"/>
                        </p>
                        <div className="dot-typing"></div>
                    </div>
                    <div className="px-9 py-6 gap-3 rounded-large text-1.5vw mt-5 bg-mainBlack ring-8 ring-dark">
                        <CurrentTimeDisplay
                            year="numeric"
                            month="long"
                            weekday="long"
                            day="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout className="justify-center">
            <div className="flex flex-col h-screen overflow-hidden bg-dark">
                <Navbar/>
                <div className="flex h-screen gap-16 px-20 pb-24">
                    <div
                        className={classNames(
                            "flex flex-col justify-around items-center w-1/3 py-8 gap-8",
                            {"w-full": !report.tires}
                        )}
                    >
                        <div className="flex flex-col gap-8">
                            {Boolean(displayName) && (
                                <Label
                                    customerInfos
                                    title={
                                        <FormattedMessage id="customer" defaultMessage="Customer"/>
                                    }
                                    content={displayName}
                                />
                            )}
                            <Label
                                customerInfos
                                title={
                                    <FormattedMessage
                          id="vehicleRegistration"
                          defaultMessage="Vehicle Registration"
                      />
                    }
                    content={report?.registration?.toUpperCase()}
                />
              </div>
              {report?.damages && (
                  <>
                    <div className="w-full h-0.5 tv:h-1 bg-white/50" />
                    <div className="flex flex-col gap-10 w-full">
                      <p className="text-1.25vw text-white/60 uppercase font-medium"><FormattedMessage id="bodyState.title" defaultMessage="Bodywork scanner result" /></p>
                      <div className="flex flex-col gap-10 w-full">
                        <Label
                            damages
                            title={
                              <FormattedMessage
                                  id="bodyState.low"
                                  defaultMessage="Low severity damages"
                              />
                            }
                            content={report.damages[DamageSeverity.NONE]}
                        />
                        <Label
                            damages
                            title={
                              <FormattedMessage
                                  id="bodyState.medium"
                                  defaultMessage="Medium severity damages"
                              />
                            }
                            content={report.damages[DamageSeverity.LOW]}
                        />
                        <Label
                            damages
                            title={
                              <FormattedMessage
                                  id="bodyState.high"
                                  defaultMessage="High severity damages"
                              />
                            }
                            content={report.damages[DamageSeverity.HIGH]}
                        />
                      </div>
                    </div>
                  </>
              )}
            </div>
            {report?.tires && (
                <div className="flex w-2/3 p-6 rounded-medium bg-white">
                  <Car tires={report.tires} />
                </div>
            )}
          </div>
        </div>
      </Layout>
  );
};

export default Summary;

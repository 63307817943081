import check from "../assets/Icons/check-group.svg";
import urgent from "../assets/Icons/urgent-group.svg";
import warning from "../assets/Icons/warning-group.svg";

export const CODE_LENGTH = 5;

export const WELCOME = ["WELCOME", "WILLKOMMEN", "BIENVENUE", "WELKOM"];

export const Civility = {
  MR: "MR",
  MS: "MS",
  COMPANY: "COMPANY",
  NONE: "NONE",
};

export const SEVERITY = {
  SEV0: "SEV0",
  SEV1: "SEV1",
  SEV2: "SEV2",
  SEV3: "SEV3",
  SEV4: "SEV4",
  SEV5: "SEV5",
};

export const NO_DMG_LIST = [SEVERITY.SEV0];

export const LOW_DMG_LIST = [
  SEVERITY.SEV1,
  SEVERITY.SEV2,
  SEVERITY.SEV3,
  SEVERITY.SEV4,
];

export const HIGH_DMG_LIST = [SEVERITY.SEV5];

export const TIRE_ORIENTATION = {
  FRONT_LEFT: "FRONT_LEFT",
  FRONT_RIGHT: "FRONT_RIGHT",
  REAR_LEFT: "REAR_LEFT",
  REAR_RIGHT: "REAR_RIGHT",
};

export const TIRE_STATE = {
  BAD_SCAN: "BAD_SCAN",
  GOOD: "GOOD",
  MARGINAL: "MARGINAL",
  REPLACE_NOW: "REPLACE_NOW",
  REPLACE_SOON: "REPLACE_SOON",
  ERROR: "ERROR",
  WARNING: "WARNING",
  UNDEFINED: "UNDEFINED",
  BAD: "BAD",
};

export enum SeasonEnum {
  ALL_SEASON = 'ALL_SEASON',
  SUMMER = 'SUMMER',
  UNSPECIFIED = 'UNSPECIFIED',
  WINTER = 'WINTER'
}

export const CONFIG = {
  [TIRE_STATE.BAD_SCAN]: {
    translation: {
      id: "tire.state.BAD_SCAN",
      defaultMessage: "Bad scan",
    },
    color: "red",
    picture: urgent,
  },
  [TIRE_STATE.ERROR]: {
    translation: {
      id: "tire.state.ERROR",
      defaultMessage: "Error",
    },
    color: "red",
    picture: urgent,
  },
  [TIRE_STATE.GOOD]: {
    translation: {
      id: "tire.state.GOOD",
      defaultMessage: "Good",
    },
    color: "green",
    picture: check,
  },
  [TIRE_STATE.MARGINAL]: {
    translation: {
      id: "tire.state.MARGINAL",
      defaultMessage: "Average level",
    },
    color: "green",
    picture: check,
  },
  [TIRE_STATE.REPLACE_NOW]: {
    translation: {
      id: "tire.state.REPLACE_NOW",
      defaultMessage: "Replace now",
    },
    color: "red",
    picture: urgent,
  },
  [TIRE_STATE.BAD]: {
    translation: {
      id: "tire.state.REPLACE_NOW",
      defaultMessage: "Replace now",
    },
    color: "red",
    picture: urgent,
  },
  [TIRE_STATE.REPLACE_SOON]: {
    translation: {
      id: "tire.state.REPLACE_SOON",
      defaultMessage: "Replace soon",
    },
    color: "orange",
    picture: warning,
  },
  [TIRE_STATE.WARNING]: {
    translation: {
      id: "tire.state.REPLACE_SOON",
      defaultMessage: "Replace soon",
    },
    color: "orange",
    picture: warning,
  },
  [TIRE_STATE.UNDEFINED]: {
    translation: {
      id: "tire.state.UNDEFINED",
      defaultMessage: "N/A",
    },
    color: "dark",
  }
};
